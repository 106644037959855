import {
  getItemFromLocalStorage as getItem,
  removeItemFromLocalStorage as removeItem,
  saveItemToLocalStorage as saveItem,
} from './local-storage.js';

type StoredData<T> = {
  _expires: number;
  value: T;
};

export const getItemFromLocalStorage = <T>(name: string): T => {
  let storedData: StoredData<T>;

  try {
    storedData = JSON.parse(getItem(name)) as StoredData<T>;
  } catch (e) {
    removeItem(name);

    return null;
  }

  if (!storedData || !storedData._expires || storedData._expires < Date.now()) {
    return null;
  }

  return storedData.value;
};

export const saveItemToLocalStorage = <T>(
  name: string,
  value: T,
  ttlMs: number,
): void => {
  const expirationTimestamp = Date.now() + ttlMs;

  const dataToStore: StoredData<T> = {
    value,
    _expires: expirationTimestamp,
  };

  saveItem(name, JSON.stringify(dataToStore));
};

export const removeItemFromLocalStorage = removeItem;
