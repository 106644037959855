import type { CacheableSessionData, SessionData } from './types.js';

import {
  getItemFromLocalStorage,
  saveItemToLocalStorage,
  removeItemFromLocalStorage,
} from '../local-storage.js';

const SESSION_CACHE_KEY = 'fasten-session-cache';

const removeNonCacheableProperties = (
  sessionData: SessionData,
): CacheableSessionData => {
  if (sessionData && sessionData.user) {
    // For security reasons remove userToken from cache
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { userToken: _discard, ...user } = sessionData.user;

    return {
      user,
      access: sessionData.access,
    };
  }

  return sessionData;
};

export const getCachedSessionData = (): CacheableSessionData | null => {
  try {
    const rawData = getItemFromLocalStorage(SESSION_CACHE_KEY);

    return rawData ? JSON.parse(rawData) : null;
  } catch (error) {
    return null;
  }
};

export const setCachedSessionData = (sessionData: SessionData): void => {
  const cachedSessionData = removeNonCacheableProperties(sessionData);

  saveItemToLocalStorage(SESSION_CACHE_KEY, JSON.stringify(cachedSessionData));
};

export const clearCachedSessionData = (): void => {
  removeItemFromLocalStorage(SESSION_CACHE_KEY);
};
