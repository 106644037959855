import React from 'react';

import type {
  BenefitsArticle,
  FrapiTeaserItem,
  BenefitsPublications,
} from '../../../types/frapi-types.js';
import type {
  Component,
  TableComponent,
} from '../../../../core/data-sources/content-components/types.js';
import type { FrapiSectionInfo } from '../../../../core/data-sources/frapi/types.js';
import type { Tag } from '../../../../core/data-sources/frapi/data-types.js';
import type { Sections } from '../../../types/front-types.js';

import Image from './Image.js';
import LinkInternal from './LinkInternal.js';
import Text from './text/index.js';

export function getFirstDescriptorTag({ tags }: { tags: Tag[] }): string {
  return tags.filter((tag) => tag.type === 'descriptor')[0]?.title;
}

export function pickComponent(
  item: Component,
  index: number,
  articleTitle: string,
  publication: BenefitsPublications,
  isMobile?: boolean,
  groupIndex?: number,
) {
  switch (item.type) {
    case 'text':
      return <Text {...item} key={`text-${index}-${groupIndex}`} />;

    case 'image':
      return (
        <div>
          <Image
            key={`image-${item.imageAsset.id}`}
            {...item}
            groupIndex={groupIndex}
            isMobile={isMobile}
            articleTitle={articleTitle}
            lazyLoad
            publication={publication}
          />
        </div>
      );

    case 'teaser':
      return <LinkInternal {...item} publication={publication} />;

    case 'breakpoint':
      return <hr className="breakpoint" />;

    default:
      return <React.Fragment key={groupIndex}></React.Fragment>;
  }
}

export const makeTagTitle = (title: string) => {
  const segments = title.split('/');
  const isPrice = segments[1] === 'pris';
  const tagTitle = segments.pop();

  if (isPrice) {
    return `Pris: ${tagTitle?.toLowerCase()}`;
  }

  return tagTitle;
};

export type BuyButton = {
  title: string;
  subtitle: string;
  buttonText: string;
  description: string;
  url: string;
};
const tablesToButton = (tables: TableComponent[]): BuyButton[] => {
  const buttons: BuyButton[] = [];
  if (!tables) return [];
  tables.forEach((table: TableComponent) => {
    table.rows.forEach((row) => {
      if (row.length === 5) {
        const bb: BuyButton = {
          title: row[0],
          subtitle: row[1],
          buttonText: row[2],
          description: row[3],
          url: row[4],
        };

        buttons.push(bb);
      }
    });
  });

  return buttons;
};

export const getBuyButtonsTeasers = (article: FrapiTeaserItem): BuyButton[] => {
  if (!article.tables) return [];

  return tablesToButton(article.tables);
};

export const getBuyButtons = (article: BenefitsArticle): BuyButton[] =>
  tablesToButton(
    article.components.filter(
      (comp) => comp.type === 'table',
    ) as TableComponent[],
  );

export type CompetitionSignup = {
  title: string;
  subtitle: string;
  description: string;
  submitText: string;
};
export const getCompetitionSignup = (article: BenefitsArticle) => {
  const [competitionSignup] = article.components.filter(
    (c) => c.type === 'table',
  ) as TableComponent[];

  if (!competitionSignup) return [];

  return competitionSignup.rows.map(
    (row) =>
      ({
        title: row[0],
        subtitle: row[1],
        description: row[3],
        submitText: row[2],
      }) satisfies CompetitionSignup,
  );
};

export const getMainSection = (item: FrapiTeaserItem) => {
  return item.section.parent ? item.section.parent.title : item.section.title;
};

export const getMainSectionId = (item: FrapiTeaserItem) => {
  return item.section.parent ? item.section.parent.id : item.section.id;
};

export const getSectionTitle = (section: FrapiSectionInfo) => {
  return section.parent ? section.parent.title : section.title;
};

export const parseExpirationDate = (
  input: string | undefined,
): Date | undefined => {
  if (!input) {
    return undefined;
  }
  const separators = /[/\s.:-]+/;
  const components = input.split(separators);
  if (components.length < 3) {
    return undefined;
  }

  const day = parseInt(components[0], 10);
  const month = parseInt(components[1], 10);
  const year = parseInt(components[2], 10);

  let hour = 0;
  let minute = 0;
  if (components.length >= 5) {
    hour = parseInt(components[3]);
    minute = parseInt(components[4]);
  }

  return new Date(year, month - 1, day, hour, minute);
};

export const filterOutExpired = (item: FrapiTeaserItem) => {
  const now = new Date();
  if (!item.presentationProperties) return true;
  const itemExpiration = parseExpirationDate(
    item.presentationProperties.expirationDate,
  );
  if (!itemExpiration) return true;

  return itemExpiration > now;
};

export enum SectionLevel {
  Frontpage = 1,
  Section,
  SubSection,
}

export const getSectionLevel = (
  activeSection: string,
  sections: Sections,
  activeSubsection?: string,
): SectionLevel => {
  if (activeSubsection) {
    return SectionLevel.SubSection;
  }

  const frontpageSection = sections[sections.length - 1].pathname;
  if (activeSection !== frontpageSection) {
    return SectionLevel.Section;
  }

  return SectionLevel.Frontpage;
};
