import React from 'react';

import { AnsvarsmerketStyled } from './Ansvarsmerket.styles.js';

const Ansvarsmerket: React.FC = () => {
  return (
    <AnsvarsmerketStyled>
      <div data-loaded="true">
        <a
          href="https://www.nored.no/Redaktoerplakaten/Redaktoerplakaten"
          target="_blank"
          rel="noopener noreferrer"
        >
          <noscript>
            <img
              src="https://cdn.aftenposten.no/ap/images/footer/ansvarsmerket.svg"
              alt="Ansvarsmerket"
            />
          </noscript>
        </a>
      </div>
    </AnsvarsmerketStyled>
  );
};

export { Ansvarsmerket };
